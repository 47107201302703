var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-menu-item',{key:_vm.alpha_3_code,attrs:{"index":_vm.alpha_3_code}},[_c('nuxt-link',{attrs:{"to":{
      name: 'search-country',
      params: {
        type: _vm.$t('router.search_type.type.country'),
        value: _vm.slug,
        tab: _vm.$t('router.search_type.tab.tours'),
        travelTo: _vm.$t('router.search_type.travel_to_'),
      },
    }},nativeOn:{"click":function($event){return _vm.handleLoading.apply(null, arguments)}}},[_vm._v("\n    "+_vm._s(_vm.name)+"\n  ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }